export default {
  state: {
    loading: false,
    platform: "",
    system: "",
    webInApp: Boolean,
  },
  mutations: {
    SHOW_LOADING(state) {
      state.loading = true;
    },
    HIDE_LOADING(state) {
      state.loading = false;
    },
    GET_DEVICE_INFORMATION(state) {
      const ua = navigator.userAgent.toLowerCase();
      const testUa = (regexp) => regexp.test(ua);

      // 系統
      if (testUa(/windows|win32|win64|wow32|wow64/gi)) {
        state.system = "windows"; // window系統
      } else if (testUa(/macintosh|macintel/gi)) {
        state.system = "macos"; // macos系統
      } else if (testUa(/x11/gi)) {
        state.system = "linux"; // linux系統
      } else if (testUa(/android|adr/gi)) {
        state.system = "android"; // android系統
      } else if (testUa(/ios|iphone|ipad|ipod|iwatch/gi)) {
        state.system = "ios"; // ios系統
      }
      //裝置平台
      if (
        state.system === "windows" ||
        state.system === "macos" ||
        state.system === "linux"
      ) {
        state.platform = "desktop"; // 桌面端
      } else if (
        state.system === "android" ||
        state.system === "ios" ||
        testUa(/mobile/gi)
      ) {
        state.platform = "mobile"; // 移動端
      }

      //webview
      if (
        testUa(
          /(WebView|(iPhone|iPod|iPad)(?!.*Safari\/)|Android.*(wv|.0.0.0))/gi
        )
      ) {
        state.webInApp = true;
      } else {
        state.webInApp = false;
      }
    },
    SENT_APP(state, sent) {
      try {
        var data = { action: sent.action, payload: sent.payload };
        if (navigator.userAgent.match(/Android/i)) {
          // eslint-disable-next-line no-undef
          app.android(JSON.stringify(data));
        } else if (
          navigator.userAgent.match(/(iOS)?/i) ||
          navigator.userAgent.match(/(iPhone)?/i)
        ) {
          window.webkit.messageHandlers.ios.postMessage(data);
        }
      } catch (e) {
        return e;
      }
    },
  },
  actions: {
    showLoading({ commit }) {
      commit("SHOW_LOADING");
    },
    hideLoading({ commit }) {
      commit("HIDE_LOADING");
    },
    getDeviceInformation({ commit }) {
      commit("GET_DEVICE_INFORMATION");
    },
    sentApp({ commit }, data) {
      console.table(data);
      commit("SENT_APP", data);
    },
  },
  getters: {
    loading: (state) => state.loading,
    platform: (state) => state.platform,
    system: (state) => state.system,
    webInApp: (state) => state.webInApp,
  },
};
