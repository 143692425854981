<template>
  <div id="app">
    <el-container :style="{ background: bgc }">
      <el-header
        height="127px"
        v-if="$route.meta.header"
        :style="{ background: headerBgc }"
      >
        <template v-if="$route.meta.header.back">
          <img
            v-if="headerColor === '#4D4D4D'"
            class="icon-back"
            @click="$router.back()"
          />
          <img
            v-else
            class="icon-back"
            @click="$router.back()"
            style="
              filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
                saturate(7450%) hue-rotate(51deg) brightness(108%) contrast(92%);
            "
          />
        </template>
        <div
          class="header-title"
          :class="{ center: headerAlign === 'center' }"
          :style="{ color: headerColor }"
        >
          {{ $route.name }}
        </div>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "app",
  mounted() {
    this.disableUserScale();
    this.setViewHeight();
  },
  computed: {
    bgc() {
      return this.$route.meta.bgc;
    },
    headerAlign() {
      return this.$route.meta?.header?.align ?? "center";
    },
    headerBgc() {
      return this.$route.meta?.header?.bgc ?? "transparent";
    },
    headerColor() {
      return this.$route.meta?.header?.color ?? "#4D4D4D";
    },
  },
  methods: {
    setViewHeight() {
      let vh = window.innerHeight;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    disableUserScale() {
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    },
  },
};
</script>

<style lang="scss"></style>
